import React from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "../../../scss/main.scss";
import Trustpilot from "../../components/Trustpilot/Trustpilot";
import CqcApproved from "../../components/CqcApproved";

import {
	ConditionSplash,
	ConditionDescription,
	ConditionDescriptionRightPanel,
	ConditionDescriptionRightPanelParagraph,
	ConditionInfographicSection,
	ConditionInfographicSectionIntro,
	ConditionInfographicMainContent,
	ConditionInfographicDescription,
	ConditionInfographicDescriptionParagraph,
	ConditionsInforgraphicTypes,
	ConditionsInfographicTypesHeading,
	ConditionsInfographicTypesDetail,
	ConditionsInfographicTypesDetailSection,
	ConditionsInfographicSymptons,
	ConditionsInfographicSymptonsHeader,
	ConditionsInfographicSymptonDiagram,
	ConditionsInfographicSymptonDiagramMainCopy,
	ConditionsInfographicSymptonDiagramTopics,
	ConditionsInfographicSymptonDiagramTopic,
	ConditionsInfographicSymptonDiagramTopicHeading,
	ConditionsInfographicSymptonDiagramTopicCopy,
	ConditionStats,
	ConditionStatsMainContent,
	ConditionStat,
	ConditionStatCopy,
	ConditionStatCopyInline,
	ConditionStatCopyEmph,
	ConditionStatCopyEmphInline,
	ConditionLivein,
	ConditionLiveinHeader,
	ConditionLiveinHeaderCopy,
	ConditionLiveinHomeCard,
	ConditionLiveinHomeCardMainCopy,
	ConditionLiveinHomeCardMainTip,
	ConditionLiveinHomeCardMainTipTitle,
	ConditionLiveinHomeCardMainTipCopy,
	ConditionTrainingCard,
	ConditionTrainingCardMainCopy,
	ConditionTrainingCardTip,
	ConditionTrainingCardTipItem,
} from '../../components/Conditions'

export default function Cancer() {
	const dementiaBackgroundFluid = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "dementia-image-02.jpg"}){
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`).background.childImageSharp.fluid
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>MS care at home</title>
				<meta property="og:title" content="Edyn Care | MS care at home"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/condition_ms_splash.jpeg"/>
				<meta property="og:url" content="https://www.edyn.care/condition/multiple-sclerosis" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/condition/multiple-sclerosis" />
				<meta name="twitter:title" content="Edyn Care | Multiple Sclerosis" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<ConditionSplash>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/circle-dementia.svg" 
							placeholder="blurred"
						/>
						<h3>Multiple Sclerosis (MS) care at home</h3>
					</ConditionSplash>
					<ConditionDescription>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/condition_ms_splash.jpeg"
							placeholder="blurred"
						/>
						<ConditionDescriptionRightPanel>
							<h3>
								Multiple Sclerosis diagnosis can happen when you’re relatively young. 
							</h3>
							<ConditionDescriptionRightPanelParagraph>
								It can be hard to watch a loved one experience the effects of a MS and the thought of moving into an elderly home or specialist setting is hugely upsetting. It’s crucial to find ways to manage looking after both yourself and your loved one.  
							</ConditionDescriptionRightPanelParagraph>
							<ConditionDescriptionRightPanelParagraph>
								At Edyn we know first hand the power of receiving the right care at home can have a positive impact on a person’s quality of life. Thanks to ongoing research, live-in care techniques and assistive technology, it is possible to live well with MS, and remain at home. We understand that everyone’s journey is different and the symptoms of MS vary between individuals and the day-to-day expression of the condition can likewise differ considerably. 
							</ConditionDescriptionRightPanelParagraph>
						</ConditionDescriptionRightPanel>
					</ConditionDescription>
					<ConditionInfographicSection>
						<ConditionInfographicSectionIntro>
							<h1>What is Multiple Sclerosis (MS)?</h1>
							<p className="secondary pro">
								Multiple sclerosis (MS) is a condition that can affect the brain and spinal cord.  
							</p>
						</ConditionInfographicSectionIntro>
						<ConditionInfographicMainContent>
							<ConditionInfographicDescription>
								<h3>
								Once diagnosed, MS stays with you for life, but treatments and specialists can help you to manage the condition and its symptoms. 

								</h3>
								<ConditionInfographicDescriptionParagraph>
									In MS, the coating that protects your nerves (myelin) is damaged. This causes a wide range of potential symptoms, including problems with vision, arm or leg movement, sensation or balance. It’s a lifelong condition that can sometimes cause serious disability, although it can occasionally be mild. In the UK people are most likely to find out they have MS in their thirties, forties and fifties. But the first signs of MS often start years earlier. Many people notice their first symptoms years before they get their diagnosis. 
								</ConditionInfographicDescriptionParagraph>
							</ConditionInfographicDescription>
							<ConditionsInforgraphicTypes>
								<ConditionsInfographicTypesHeading>
									There are three main types of MS:
								</ConditionsInfographicTypesHeading>
								<ConditionsInfographicTypesDetail>
									<ConditionsInfographicTypesDetailSection title={`Replasing`}>
										<ul>
											<li>
												In relapsing remitting MS (RRMS) people have attacks of new and old symptoms, this is called a relapse.
											</li>
											<li>
												Around 85% of people with multiple sclerosis are diagnosed with RRMS. 
											</li>
											<li>
												Taking a disease modifying therapy (DMT) could mean fewer relapses and slow down your MS. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Primary progressive`}>
										<ul>
											<li>
												It has this name because from the first (primary) symptoms it is professive. Symptoms gradually get worse over time, rather than appearing as sudden relapses. 
											</li>
											<li>
												In primary progressive MS, early symptoms are often subtle problems with walking, which develop - often slowly - over time. 
											</li>
											<li>
												This type of MS is usually diagnosed in people in their forties or fifties.
											</li>
											<li>
												Equal numbers of men and women have primary progressive MS. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Secondary progressive`}>
										<ul>
											<li>
												Secondary progressive MS (SPMS) is a stage of MS which comes after relapsing remitting MS for many people. With this type of MS your disability gets steadily worse. 
											</li>
											<li>
												You're no longer likely to have relapses, when your symptoms get worse but then get better. 
											</li>
											<li>
												In the past, before DMT came along, it usually took around 20 years for relapsing MS to change into secondary progressive MS. But thanks to today’s MS drugs this is changing. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
								</ConditionsInfographicTypesDetail>
							</ConditionsInforgraphicTypes>

							<ConditionsInfographicSymptons>
								<ConditionsInfographicSymptonsHeader>
									The central nervous system links everything your body does, so multiple sclerosis can cause many different types of symptoms.								</ConditionsInfographicSymptonsHeader>
								<ConditionsInfographicSymptonDiagram>
									<ConditionsInfographicSymptonDiagramMainCopy>
										The specific symptoms that appear depend on which part of your central nervous system has been affected, and the job of the damaged nerve. 
									</ConditionsInfographicSymptonDiagramMainCopy>
									<ConditionsInfographicSymptonDiagramTopics>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Eyes and sight:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Partial or complete loss of vision,usually in one eye at a time, often with pain during eye movement. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Balance, walking and dizziness:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												You might be wobbly on your feet from time to time, or you might need to move with more care than before to avoid losing balance.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Memory and thinking: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Cognitive problems can affect people with MS, but most people will be affected mildly.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Mental health: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												It’s not unusual to experience depression, stress and anxiety when you have MS. Medication, talking therapies and self-help techniques can all make it easier to cope. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Unexplained pain or ache: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Pain is one way our bodies tell us that something is wrong. As we get older, it’s more common to experience aches and pains. But unexplained pain could be a sign of something more serious. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
									</ConditionsInfographicSymptonDiagramTopics>
								</ConditionsInfographicSymptonDiagram>
							</ConditionsInfographicSymptons>
						</ConditionInfographicMainContent>
					</ConditionInfographicSection>
					<ConditionStats>
						<BackgroundImage className="f-c-image" fluid={dementiaBackgroundFluid}>
							<h1>MS facts and stats</h1>
						</BackgroundImage>
						<ConditionStatsMainContent>
							<ConditionStat>
								<ConditionStatCopyEmph>Over 130,000</ConditionStatCopyEmph>
								<ConditionStatCopy>
									people in the UK have MS
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>MS is the</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>most common</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									disabling neurological disease of young adults in the UK.
								</ConditionStatCopy>
							</ConditionStat>

							<ConditionStat>
								<ConditionStatCopyInline>Most people are diagnosed with MS in their </ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>20s and 30s</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									affecting more women than men
								</ConditionStatCopy>
							</ConditionStat>

							<ConditionStat>
								<ConditionStatCopy>
									Multiple sclerosis in not inherited
								</ConditionStatCopy>
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Females in the 50 to 59 year age group are </ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>3 times</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									more likely than males of a similar age to have MS
								</ConditionStatCopy>
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>Nearly 7,000</ConditionStatCopyEmph>
								<ConditionStatCopy>
									people are newly diagnosed each year
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
						</ConditionStatsMainContent>
					</ConditionStats>
					<ConditionLivein>
						<ConditionLiveinHeader>
							<h1>MS live-in care </h1>
							<ConditionLiveinHeaderCopy>
								We understand that with patience, trust and genuine care, we can help those with MS maintain a high quality of life and live comfortably in their own home. 
							</ConditionLiveinHeaderCopy>
						</ConditionLiveinHeader>
						<ConditionLiveinHomeCard title={`Ensuring your home is MS friendly`}>
							<ConditionLiveinHomeCardMainCopy>
								When considering live-in care it’s important to ensure that the home of the care recipient is safe and secure. Does it compensate for any difficulties they are experiencing? Does it keep them safe? Does it support their mobility?
								<br />
								<br />
								At Edyn as part of our approach we complete a home risk
								assessment for all of our clients. Our care managers ensure
								that your home is the best possible setting for care. Small
								changes can make big differences.
							</ConditionLiveinHomeCardMainCopy>
							<ConditionLiveinHomeCardMainTip>
								<ConditionLiveinHomeCardMainTipTitle>Top MS care tip:</ConditionLiveinHomeCardMainTipTitle>
								<br />
								<ConditionLiveinHomeCardMainTipCopy>
									Ensuring mobility areas are safe with adequate space and having a dedicated area for medication with a well defined medication schedule are both crucial
								</ConditionLiveinHomeCardMainTipCopy>
							</ConditionLiveinHomeCardMainTip>
						</ConditionLiveinHomeCard>
						<ConditionTrainingCard title={`Professional carers trained specifically for Multiple Sclerosis:`}>
							<ConditionTrainingCardMainCopy>
								Our professional care teams receive on-going training, mentoring and support based on the latest thinking and research that ensure they have the skills to deliver positive MS care. This includes:
							</ConditionTrainingCardMainCopy>
							<ConditionTrainingCardTip>
								<ConditionTrainingCardTipItem>Managing and administering medications</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Providing emotional support</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Moving and handling support</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Cognitive exercises</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Managing physiotherapy and occupational therapy</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Running errands, such as collecting prescriptions and shopping for food</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Managing your hospital visits</ConditionTrainingCardTipItem>
							</ConditionTrainingCardTip>
						</ConditionTrainingCard>
					</ConditionLivein>
					{/* <section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.
										<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<div className="customer-images-bubbles">
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers1.png" />
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers2.png" />
								</div>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section> */}

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>

					{/*   Trust pilot embed goes here  */}
					<section>
						<div className="trust pilot"> </div>
					</section>

					{/* <Trustpilot /> */}
					<CqcApproved />
				</main>
				<Footer />
			</div>
		</>
	);
}
